import React from "react";
import axios from "axios";
import { browserName, isMobile } from "react-device-detect";
import Laroutebas from "./components/laroutebas";
import Likes from "./components/Likes";
import Delete from "./components/Delete";
import Poke from "./components/Poke";
//import Publicity from "./components/Publicity";
import Intro from "./components/Intro";
import Online from "./components/Online";
import News from "./components/News";
import Remove from "./components/Remove";
import Sidebar from "./components/Sidebar";
import Location from "./components/Location";
import Secret from "./components/Secret";
import Cartoon from "./components/Cartoon";
import { Link } from "react-router-dom";
//import Toggle from "./components/toggle";
import Comment from "./components/Comment";

//client 72944838415-9u3d201g3ks5peekus6ar6o14b5b3dab.apps.googleusercontent.com
//sec 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

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      user: "usr",
      quote: "",
      ext_link: "",
      url_referer: document.referrer,
      is_mobile: isMobile,
      type_browser: browserName,
      //guid: "",
      photo_url: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // On file select (from the pop up)
  onFileChange = (event) => {
    event.preventDefault();
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    this.setState({ photo_url: event.target.files[0].name });
    this.setState({ file2: URL.createObjectURL(event.target.files[0]) });
  };

  // On file upload (click the upload button)
  onFileUpload = (event) => {
    if (this.state.selectedFile !== null) {
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append(
        "myFile",
        this.state.selectedFile,
        this.state.selectedFile.name
      );

      // Send formData object
      axios
        .post("https://reactapimekone.azurewebsites.net/api/pictures", formData)
        //.then(response => this.setState({ pictureUrl: response.statusText }))
        .catch((error) => {
          this.setState({ errorMessage: error.message });
          console.error("There was an error!", error);
        });
    }
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        this.state.file2 !== null && (
          <div>
            <hr />
            <img
              src={this.state.file2}
              style={{ width: "100%" }}
              alt="preview"
            />
          </div>
        )
      );
    }
  };

  handleChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  componentWillMount() {
    this.getGeoInfo();
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 10000);

    //this.getGeoInfo();

    const vivi = {
      name: "-",
      detail: "-",
      guid: "-", //sessionStorage.getItem("guid"),
      picture_url: "-", //sessionStorage.getItem("picture_url"),
      email: "-", //sessionStorage.getItem("email"),
      category: "visitor",
      ip: sessionStorage.getItem("ip"),
      network: sessionStorage.getItem("network"),
      version: sessionStorage.getItem("version"),
      city: sessionStorage.getItem("city"),
      region: sessionStorage.getItem("region"),
      region_code: sessionStorage.getItem("region_code"),
      country: sessionStorage.getItem("country"),
      country_name: sessionStorage.getItem("country_name"),
      country_code: sessionStorage.getItem("country_code"),
      country_code_iso3: sessionStorage.getItem("country_code_iso3"),
      country_capital: sessionStorage.getItem("country_capital"),
      country_tld: sessionStorage.getItem("country_tld"),
      continent_code: sessionStorage.getItem("continent_code"),
      in_eu: sessionStorage.getItem("in_eu"),
      postal: sessionStorage.getItem("postal"),
      latitude: sessionStorage.getItem("latitude"),
      longitude: sessionStorage.getItem("longitude"),
      timezone: sessionStorage.getItem("timezone"),
      utc_offset: sessionStorage.getItem("utc_offset"),
      country_calling_code: sessionStorage.getItem("country_calling_code"),
      currency: sessionStorage.getItem("currency"),
      currency_name: sessionStorage.getItem("currency_name"),
      languages: sessionStorage.getItem("languages"),
      country_area: sessionStorage.getItem("country_area"),
      country_population: sessionStorage.getItem("country_population"),
      asn: sessionStorage.getItem("asn"),
      org: sessionStorage.getItem("org"),
      //pub_date: currentDate,
      views_count: 0,
      likes_count: 0,
      dislikes_count: 0,
      flags_count: 0,
      photo_url: "-",
      post_status: "-",
      phone: "-",
      whatsapp: "-",
      education: "twitter",
      profession: window.location.href,
      req_education: "-",
      req_experience: "-",
      req_location: "-",
      req_industry: "-",
      req_sector: "-",
      req_salary: "-",
      req_term: "-",
      req_level: "-",
      req_benefits: "-",
      req_clearance: "-",
      req_certification: "-",
      req_linkedin: "-",
      ext_link: "-",
      url_referer: this.state.url_referer,
      is_mobile: this.state.is_mobile,
      type_browser: this.state.type_browser,
    };

    axios
      .post("https://sokika-api.azurewebsites.net/api/exposed/", vivi)
      .then((res) => {
        this.setState({
          //user: "",
          //quote: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  tick() {
    let data;

    axios
      .get("https://sokika-api.azurewebsites.net/api/twitter/")
      .then((res) => {
        data = res.data;
        this.setState({
          details: data,
        });
      })
      .catch((err) => {});
  }

  renderSwitch = (param) => {
    switch (param + 1) {
      case 1:
        return "info";
      case 2:
        return "info";
      case 3:
        return "info";
      case 4:
        return "info";
      case 5:
        return "info";
      case 6:
        return "info";
      default:
        return "yellow";
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const article = {
      name: this.state.user,
      detail: this.state.quote,
      guid: sessionStorage.getItem("guid"),
      picture_url: sessionStorage.getItem("picture_url"),
      email: sessionStorage.getItem("email"),
      category: "main",
      ip: sessionStorage.getItem("ip"),
      network: sessionStorage.getItem("network"),
      version: sessionStorage.getItem("version"),
      city: sessionStorage.getItem("city"),
      region: sessionStorage.getItem("region"),
      region_code: sessionStorage.getItem("region_code"),
      country: sessionStorage.getItem("country"),
      country_name: sessionStorage.getItem("country_name"),
      country_code: sessionStorage.getItem("country_code"),
      country_code_iso3: sessionStorage.getItem("country_code_iso3"),
      country_capital: sessionStorage.getItem("country_capital"),
      country_tld: sessionStorage.getItem("country_tld"),
      continent_code: sessionStorage.getItem("continent_code"),
      in_eu: sessionStorage.getItem("in_eu"),
      postal: sessionStorage.getItem("postal"),
      latitude: sessionStorage.getItem("latitude"),
      longitude: sessionStorage.getItem("longitude"),
      timezone: sessionStorage.getItem("timezone"),
      utc_offset: sessionStorage.getItem("utc_offset"),
      country_calling_code: sessionStorage.getItem("country_calling_code"),
      currency: sessionStorage.getItem("currency"),
      currency_name: sessionStorage.getItem("currency_name"),
      languages: sessionStorage.getItem("languages"),
      country_area: sessionStorage.getItem("country_area"),
      country_population: sessionStorage.getItem("country_population"),
      asn: sessionStorage.getItem("asn"),
      org: sessionStorage.getItem("org"),
      //pub_date: currentDate,
      views_count: 0,
      likes_count: 0,
      dislikes_count: 0,
      flags_count: 0,
      photo_url:
        "https://reactcampusstorage.blob.core.windows.net/reactimages/" +
        this.state.photo_url,
      post_status: "active",
      phone: "+0000000000",
      whatsapp: "+0000000000",
      education: "twitter",
      profession: window.location.href,
      req_education: "req",
      req_experience: "req",
      req_location: "req",
      req_industry: "req",
      req_sector: "req",
      req_salary: "req",
      req_term: "req",
      req_level: "req",
      req_benefits: "req",
      req_clearance: "req",
      req_certification: "req",
      req_linkedin: "req",
      ext_link: this.state.ext_link,
      url_referer: this.state.url_referer,
      is_mobile: this.state.is_mobile,
      type_browser: this.state.type_browser,
    };

    this.setState({
      file2: null,
    });

    axios
      .post("https://sokika-api.azurewebsites.net/api/exposed", article)
      .then((response) =>
        this.setState({
          quote: "",
          ext_link: "Post successful!",
          //photo_url: "",
        })
      )
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  };

  render() {
    return (
      <div>
        <Laroutebas />
        <p style={{ marginTop: "60px" }}></p>
        <div
          className=""
          style={{
            backgroundColor: "",
            padding: "10px",
            margin: "10px",
          }}
        >
          <div
            class="form-group"
            style={{
              cursor: "pointer",
            }}
          >
            <Intro />
          </div>
          <table style={{ width: "100%" }}>
            <tr>
              <td>
                {" "}
                <a
                  href="https://azure.microsoft.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://reactcampusstorage.blob.core.windows.net/reactimages/msft.jpg"
                    alt="Publicity"
                    style={{
                      width: "100%",
                      height: "350px",
                      borderRadius: "5%",
                      padding: "3px",
                    }}
                  />
                </a>
              </td>{" "}
              <a href="https://aws.amazon.com" target="_blank" rel="noreferrer">
                <img
                  src="https://reactcampusstorage.blob.core.windows.net/reactimages/amz2.jpg"
                  alt="ngambaye"
                  style={{
                    width: "100%",
                    height: "350px",
                    borderRadius: "5%",
                    padding: "3px",
                  }}
                />
              </a>
              <td></td>
            </tr>
            <tr>
              <td>
                {" "}
                <a
                  href="https://spark.apache.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://reactcampusstorage.blob.core.windows.net/reactimages/spk.png"
                    alt="ngambaye"
                    style={{
                      width: "100%",
                      height: "350px",
                      borderRadius: "5%",
                      padding: "3px",
                    }}
                  />
                </a>
              </td>
              <td>
                {" "}
                <a
                  href="https://www.databricks.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://reactcampusstorage.blob.core.windows.net/reactimages/dbx.jpg"
                    alt="ngambaye"
                    style={{
                      width: "100%",
                      height: "350px",
                      borderRadius: "5%",
                      padding: "3px",
                    }}
                  />
                </a>
              </td>
            </tr>
          </table>
        </div>

        {sessionStorage.getItem("email") === "ngambaye2020@gmail.com" ? (
          <div style={{ padding: "10px", margin: "10px" }}>
            <span
              style={{
                fontSize: "14px",
                fontFamily: "Poller One",
                color: "maroon",
              }}
            >
              <Secret />
            </span>
          </div>
        ) : (
          <div
            style={{
              padding: "10px",
              marginLeft: "10px",
              marginRight: "10px",
              fontFamily: "Fahkwang",
              fontStyle: "italic",
              fontSize: "2em",
            }}
          >
            <Link to="/careers">
              Hire a Solutions Architect today, Big Data, Big Time!
            </Link>
          </div>
        )}

        <div
          style={{
            backgroundColor: "",
            padding: "10px",
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "10px",
          }}
        >
          <div>
            <form onSubmit={this.handleSubmit}>
              <div style={{ maxWidth: "100%" }}>
                <div
                  class="form-group"
                  style={{
                    padding: "5px",
                  }}
                >
                  <br />
                  <input
                    defaultValue={this.state.photo_url}
                    name="photo_url"
                    type="file"
                    onChange={this.onFileChange}
                    className="form-control-file"
                    id="exampleFormControlFile1"
                    style={{ display: "none" }}
                  />
                  <label for="exampleFormControlFile1">
                    <span
                      style={{
                        cursor: "pointer",
                        fontFamily: "Poller One",
                      }}
                    >
                      <span>🚀</span> Upload Image <span>🚀</span>
                    </span>
                  </label>
                  <Online />
                </div>
                {this.fileData()}

                <div className="form-group">
                  <textarea
                    class="form-control"
                    rows="5"
                    id="quote"
                    name="quote"
                    value={this.state.quote}
                    maxLength={850}
                    onChange={this.handleInput}
                    placeholder="Tell me in 850 characters ..."
                    required
                    style={{
                      backgroundColor: "lightyellow",
                      border: "1px solid green",
                    }}
                  ></textarea>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Paste external link (OPTIONAL) ..."
                    maxLength={500}
                    value={this.state.ext_link}
                    id="ext_link"
                    name="ext_link"
                    onChange={this.handleInput}
                    style={{
                      backgroundColor: "lightyellow",
                      border: "1px solid green",
                    }}
                  />
                </div>
              </div>

              <div class="d-grid gap-2">
                <button
                  onClick={this.onFileUpload}
                  type="submit"
                  class="btn btn-primary btn-block"
                  style={{
                    fontFamily: "Poller One",
                    fontSize: "2em",
                    padding: "10px",
                  }}
                >
                  NGAMBAYE
                </button>
              </div>
            </form>
          </div>

          <hr
            style={{
              color: "#000000",
              backgroundColor: "#000000",
              height: 0.5,
              borderColor: "#000000",
            }}
          />

          <div className="row">
            <div className="col-md-8">
              {this.state.details.map((detail, id) => (
                <div key={id}>
                  <div
                    className="card shadow-lg"
                    style={{ fontFamily: "Roboto" }}
                  >
                    <div
                      className={
                        "bg-" + this.renderSwitch(id % 6) + " card-header"
                      }
                    >
                      {detail.ext_link.includes(
                        "https://www.youtube.com/embed"
                      ) ? (
                        <iframe
                          width="100%"
                          height="315"
                          src={detail.ext_link}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                          style={{ borderRadius: "3%" }}
                        ></iframe>
                      ) : (
                        <a
                          href={detail.ext_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={detail.photo_url}
                            style={{ borderRadius: "5%" }}
                            alt="www.sokika.com"
                            width="100%"
                          />{" "}
                        </a>
                      )}
                      <p
                        style={{
                          borderLeft: "1px solid green",
                          borderRight: "1px solid green",
                        }}
                      >
                        <br />
                        <Likes {...detail} /> <Delete {...detail} />{" "}
                        <Poke {...detail} />
                        {sessionStorage.getItem("email") ===
                        "ngambaye2020@gmail.com" ? (
                          <News />
                        ) : (
                          <Remove />
                        )}
                        <br />
                        <span style={{ padding: "10px", fontSize: "0.9em" }}>
                          <i>
                            by {detail.guid}, {detail.req_term}
                          </i>
                        </span>
                      </p>
                    </div>
                    <div
                      className="card-body"
                      style={
                        {
                          //borderLeft: "1px solid green",
                          //borderRight: "1px solid green",
                          //borderBottom: "1px solid green",
                          //borderRadius: "0%",
                        }
                      }
                    >
                      <blockquote
                        className={
                          "text-" +
                          this.renderSwitch(id % 6) +
                          " blockquote mb-0"
                        }
                      >
                        <p
                          style={{
                            fontFamily: "Roboto",
                            color: "black",
                            fontSize: "0.9em",
                          }}
                        >
                          {" "}
                          {detail.detail}{" "}
                        </p>
                        <footer className="blockquote-footer">
                          <cite title="Source Title">
                            <a
                              href={detail.ext_link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              External Link
                            </a>
                            {"   "}
                            <Comment {...detail} />
                          </cite>
                        </footer>
                      </blockquote>
                    </div>
                    <br />
                  </div>
                  <span className="border border-primary "></span>
                </div>
              ))}
            </div>
            <div className="col-md-4">
              <Sidebar />
              <Cartoon />
            </div>
          </div>
          <div style={{ marginBottom: "30px" }}>
            <button
              data-toggle="collapse"
              data-target="#demo"
              style={{
                width: "100%",
                padding: "5px",
                fontFamily: "Poller One",
                fontSize: "2em",
              }}
            >
              Solutions Architect 2020
            </button>
            {sessionStorage.getItem("email") === "ngambaye2020@gmail.com" ? (
              <div id="demo" class="collapse">
                <div className="row">
                  <div className="col-md-3">
                    <Location name="Cities" />
                  </div>
                  <div className="col-md-3">
                    <Location name="Posters" />
                  </div>
                  <div className="col-md-3">
                    <Location name="Likes" />
                  </div>
                  <div className="col-md-3">
                    <Location name="Countries" />
                  </div>
                </div>
              </div>
            ) : (
              <p
                style={{
                  padding: "7px",
                  marginBottom: "30px",
                  fontSize: "1.5em",
                }}
              >
                As a Solutions Architect: 1 - I discuss business requirements
                with stakeholders, design and implement the solution. 2 - I have
                a solid academic education in networking, databases, programming
                and cloud computing 3 - My skills include full stack application
                development, DevOps and Site Reliability Engineering. 4 - I have
                15 years of professional experience at Microsoft in multiple
                Azure engineering roles. 5 - I find joy in exploring new
                technologies, building proof of concept and mentoring.
              </p>
            )}
          </div>
          <br />
        </div>
      </div>
    );
  }
  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        this.setState({
          ip: sessionStorage.setItem("ip", data.ip),
          network: sessionStorage.setItem("network", data.network),
          version: sessionStorage.setItem("version", data.version),
          city: sessionStorage.setItem("city", data.city),
          region: sessionStorage.setItem("region", data.region),
          region_code: sessionStorage.setItem("region_code", data.region_code),
          country: sessionStorage.setItem("country", data.country),
          country_name: sessionStorage.setItem(
            "country_name",
            data.country_name
          ),
          country_code: sessionStorage.setItem(
            "country_code",
            data.country_code
          ),
          country_code_iso3: sessionStorage.setItem(
            "country_code_iso3",
            data.country_code_iso3
          ),
          country_capital: sessionStorage.setItem(
            "country_capital",
            data.country_capital
          ),
          country_tld: sessionStorage.setItem("country_tld", data.country_tld),
          continent_code: sessionStorage.setItem(
            "continent_code",
            data.continent_code
          ),
          in_eu: sessionStorage.setItem("in_eu", data.in_eu),
          postal: sessionStorage.setItem("postal", data.postal),
          latitude: sessionStorage.setItem("latitude", data.latitude),
          longitude: sessionStorage.setItem("longitude", data.longitude),
          timezone: sessionStorage.setItem("timezone", data.timezone),
          utc_offset: sessionStorage.setItem("utc_offset", data.utc_offset),
          country_calling_code: sessionStorage.setItem(
            "country_calling_code",
            data.country_calling_code
          ),
          currency: sessionStorage.setItem("currency", data.currency),
          currency_name: sessionStorage.setItem(
            "currency_name",
            data.currency_name
          ),
          languages: sessionStorage.setItem("languages", data.languages),
          country_area: sessionStorage.setItem(
            "country_area",
            data.country_area
          ),
          country_population: sessionStorage.setItem(
            "country_population",
            data.country_population
          ),
          asn: sessionStorage.setItem("asn", data.asn),
          org: sessionStorage.setItem("org", data.org),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export default App;
