import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
//import 'bootstrap/dist/css/bootstrap.min.css';

//import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
//import Blogs from "./pages/Blogs";
//import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Aboutus from "./pages/Aboutus";
import Solutions from "./pages/Solutions";
import Customers from "./pages/Customers";
import Careers from "./pages/Careers";
import Devops from "./pages/Devops";
import Charities from "./pages/Charities";
import Business from "./pages/Business";
import Details from "./pages/Details";

export default function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="aboutus" element={<Aboutus />} />
          <Route path="solutions" element={<Solutions />} />
          <Route path="customers" element={<Customers />} />
          <Route path="careers" element={<Careers />} />
          <Route path="devops" element={<Devops />} />
          <Route path="charities" element={<Charities />} />
          <Route path="business" element={<Business />} />
          <Route path="details" element={<Details />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="72944838415-9u3d201g3ks5peekus6ar6o14b5b3dab.apps.googleusercontent.com">
    <React.StrictMode>
      <Main />
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
