import React from "react";
import axios from "axios";
import News from "./News";
import Remove from "./Remove";
import { Link } from "react-router-dom";
//import LoadingIcons from 'react-loading-icons'

//client 72944838415-9u3d201g3ks5peekus6ar6o14b5b3dab.apps.googleusercontent.com
//sec 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

class Listings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      rk:'',
      pk:'',
      photo_url: '',
      //name: props,
    };
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {}

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 10000);
  }

  tick() {
    let data;

    axios
      .get("https://sokika-api.azurewebsites.net/api/solutions/")
      .then((res) => {
        data = res.data;
        this.setState({
          details: data,
        });
      })
      .catch((err) => {});
  }

      // On badge click
  onBadgeClick = (event) => {
    sessionStorage.setItem('RowKey', this.state.rk);
    sessionStorage.setItem('PartitionKey', this.state.pk);
    sessionStorage.setItem('photo_url');
  };

  renderSwitch = (param) => {
    switch (param + 1) {
      case 1:
        return "info";
      case 2:
        return "info";
      case 3:
        return "info";
      case 4:
        return "info";
      case 5:
        return "info";
      case 6:
        return "info";
      default:
        return "yellow";
    }
  };

  render() {
    return (
        <div className="row" style={{margin:"10px"}}>
            <div className="col-md-12">
              {this.state.details.map((detail, id) => (
                <div key={id}>
                  <div
                    className="card shadow-lg"
                    style={{ fontFamily: "Roboto" }}
                  >
                    <div
                      className={
                        "bg-" + this.renderSwitch(id % 6) + " card-header"
                      } style={{padding:"10px"}}
                    > 
                      <p style={{borderLeft:"1px solid green",borderRight:"1px solid green",borderBottom:"1px solid green"}}>
                        {sessionStorage.getItem("email") ===
                        "ngambaye2020@gmail.com" ? (
                          <Remove />
                        ) : (
                          <News />
                        )}
                      </p>
                      <p>{detail.detail}<br />{detail.ext_link}</p>
                      {this.setState({pk:detail.PartitionKey, rk:detail.RowKey, photo_url:detail.photo_url})}
                      <p><i>city={detail.city}, country={detail.country}, email={detail.email}, org={detail.org}, pub={detail.pub_date}, like={detail.likes_count}, mobile={detail.is_mobile}</i></p>
                      <p><Link to="/details" onClick={this.onBadgeClick} class="badge badge-info" style={{fontFamily:"Poller One", margin:"5px"}}>EDIT ALERT</Link></p>
                    </div>
                    <br />
                  </div>
                  <span className="border border-primary "></span>
                </div>
              ))}
            </div>
       </div>
    );
  }
}

export default Listings;
