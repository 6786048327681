import Ngambaye from "../components/ngambaye";
import Sidebar from "../components/Sidebar";

const Careers = () => {
  return (
    <div style={{ padding: "10px", margin: "10px" }}>
      <p style={{ marginTop: "60px", fontFamily: "Poller One" }}></p>
      <div className="row">
      <div className="col-md-8">
          Professional Summary
          <br /><br />
          Innovative AI Engineer with 5 years of experience in designing, developing, and deploying AI models and solutions across various industries. Proficient in machine learning, deep learning, natural language processing, and computer vision. Skilled in leveraging cutting-edge technologies and cloud platforms to deliver scalable AI solutions that solve complex business challenges and drive innovation.
          <br />
          <br />
          Core Competencies
          <br /><br />
          <ul>
          <li>Machine Learning & Deep Learning</li>
          <li>Natural Language Processing (NLP)</li>
          <li>Computer Vision</li>
          <li>AI Model Development & Deployment</li>
          <li>Cloud Platforms (AWS, Azure, GCP)</li>
          <li>Data Engineering & Preprocessing</li>
          <li>MLOps & CI/CD Pipelines</li>
          <li>Programming Languages (Python, R, Java, C++)</li>
          <li>AI Frameworks (TensorFlow, PyTorch, Keras, Scikit-learn)</li>
          <li>Data Visualization & Analytics</li>
          </ul>
          Solutions Architect
          <br /><br />
          As a Solutions Architect: 1 - I discuss business requirements with
          stakeholders, design and implement the solution. 2 - I have a solid
          academic education in networking, databases, programming and cloud
          computing 3 - My skills include full stack application development,
          DevOps and Site Reliability Engineering. 4 - I have 15 years of
          professional experience at Microsoft in multiple Azure engineering
          roles. 5 - I find joy in exploring new technologies, building proof of
          concept and mentoring.
        </div>
        <div className="col-md-4">
          <Sidebar />
        </div>
      </div>
      <p style={{ marginBottom: "60px", fontFamily: "Poller One" }}></p>
    </div>
  );
};

export default Careers;
