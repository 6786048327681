import axios from "axios";
import React, { Component } from "react";
//import * as ReactBootstrap from "react-bootstrap";

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //countoflikes: "",
      //name: "detail",
      exposed: props,
      //email: "",
      retour: "",
    };
    this.handleSubmit = this.handleClick.bind(this);
  }

  componentDidMount() {
    //this.interval = setInterval(() => this.tick(), 5000);
  }

  handleClick = (event) => {
    //event.preventDefault();

    //console.log(this.props);
    const noti = {
      email: sessionStorage.getItem("email"),
      city: sessionStorage.getItem("city"),
      country: sessionStorage.getItem("country"),
      org: sessionStorage.getItem("org"),
      ip: sessionStorage.getItem("ip"),
    };

    axios
      .post("https://sokika-api.azurewebsites.net/api/customer", noti)
      .then((response) => this.setState({ retour: "Awesome!" }))
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
    alert("Hey Hacker!");
  };

  render() {
    return (
      <span
        style={{
          border: "1px solid green",
          marginLeft: "10px",
          cursor: "pointer",
          padding:"5px"
        }}
        onClick={() => this.handleClick(this)}
        class="badge bg-warning text-dark"
      >
        <span>🚀</span> News
      </span>
    );
  }
}

export default News;
