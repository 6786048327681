import React from "react";
import axios from "axios";
import Likes from "./Likes";
import Delete from "./Delete";
import Poke from "./Poke";
import News from "./News";
import Remove from "./Remove";

//client 72944838415-9u3d201g3ks5peekus6ar6o14b5b3dab.apps.googleusercontent.com
//sec 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

class Ngambaye extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      name: props,
    };
    //this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {}

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 10000);
  }

  tick() {
    let data;

    const person = {
        name: this.props.name,
      };

    axios
      .post("https://sokika-api.azurewebsites.net/api/solutions/", person)
      .then((res) => {
        data = res.data;
        this.setState({
          details: data,
        });
      })
      .catch((err) => {});
  }

  renderSwitch = (param) => {
    switch (param + 1) {
      case 1:
        return "info";
      case 2:
        return "info";
      case 3:
        return "info";
      case 4:
        return "info";
      case 5:
        return "info";
      case 6:
        return "info";
      default:
        return "yellow";
    }
  };

  render() {
    return (
        <div className="row">
            <div className="col-md-12">
              {this.state.details.map((detail, id) => (
                <div key={id}>
                  <div
                    className="card shadow-lg"
                    style={{ fontFamily: "Roboto" }}
                  >
                    <div
                      className={
                        "bg-" + this.renderSwitch(id % 6) + " card-header"
                      }
                    >
                      {detail.ext_link.includes(
                        "https://www.youtube.com/embed"
                      ) ? (
                        <iframe
                          width="100%"
                          height="315"
                          src={detail.ext_link}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin"
                          allowfullscreen
                          style={{ borderRadius: "3%" }}
                        ></iframe>
                      ) : (
                        <a
                          href={detail.ext_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={detail.photo_url}
                            style={{ borderRadius: "5%" }}
                            alt="pressetchadienne.com"
                            width="100%"
                          />{" "}
                        </a>
                      )}
                      <p style={{borderLeft:"1px solid green",borderRight:"1px solid green",borderBottom:"1px solid green"}}>
                      <br /><Likes {...detail} /> <Delete {...detail} />{" "}
                        <Poke {...detail} />
                        {sessionStorage.getItem("email") ===
                        "ngambaye2020@gmail.com" ? (
                          <News />
                        ) : (
                          <Remove />
                        )}
                        <br />
                        <span style={{ padding: "10px", fontSize: "0.9em" }}>
                          <i>
                            by {detail.guid}, {detail.pub_date}
                          </i>
                        </span>
                      </p>
                      <div
                      className="card-body"
                    >
                      <blockquote
                        className={
                          "text-" +
                          this.renderSwitch(id % 6) +
                          " blockquote mb-0"
                        }
                      >
                        <p
                          style={{
                            fontFamily: "Roboto",
                            color: "black",
                            fontSize: "0.9em",
                          }}
                        >
                          {" "}
                          {detail.detail}{" "}
                        </p>
                      </blockquote>
                    </div>
                    </div>
                    <br />
                  </div>
                  <span className="border border-primary "></span>
                </div>
              ))}
            </div>
       </div>
    );
  }
}

export default Ngambaye;
