import React from "react";
import axios from "axios";
import { browserName, isMobile } from "react-device-detect";
import Laroutebas from "../components/laroutebas";
import Online from "../components/Online";

//client 72944838415-9u3d201g3ks5peekus6ar6o14b5b3dab.apps.googleusercontent.com
//sec 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

class EditItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      //user: "usr",
      //guid: "",
      email: sessionStorage.getItem('email'),
      detail: 'Loading... Please wait.',
      ext_link: 'optional link...',
      url_referer: document.referrer,
      is_mobile: isMobile,
      type_browser: browserName,
      photo_url: sessionStorage.getItem('photo_url'),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // On file select (from the pop up)
  onFileChange = (event) => {
    event.preventDefault();
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
    this.setState({ photo_url: event.target.files[0].name });
    this.setState({ file2: URL.createObjectURL(event.target.files[0]) });
  };


  // On file upload (click the upload button)
  onFileUpload = (event) => {
    if (this.state.selectedFile !== null) {
      if(this.state.selectedFile !== 'undefined'){
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append(
        "myFile",
        this.state.selectedFile,
        this.state.selectedFile.name
      );

      // Send formData object
      axios
        .post("https://reactapimekone.azurewebsites.net/api/pictures", formData)
        //.then(response => this.setState({ pictureUrl: response.statusText }))
        .catch((error) => {
          this.setState({ errorMessage: error.message });
          console.error("There was an error! onFileUpload: ", error);
        });
    }
  }
  };

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        this.state.file2 !== null && (
          <div>
            <hr />
            <img
              src={this.state.file2}
              style={{ width: "100%" }}
              alt="preview"
            />
          </div>
        )
      );
    }
  };

  handleChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  componentWillMount() {
    this.getGeoInfo();
  }

  componentDidMount() {
    //this.interval = setInterval(() => this.tick(), 10000);
    let data;

    var rok = sessionStorage.getItem('RowKey');
    var par = sessionStorage.getItem('PartitionKey')
    //alert(rok + ', ' + par)

    const vivi = {
        Rowkey: rok,
        PartitionKey: par,
        email: this.state.email,
        url_referer: this.state.url_referer,
        is_mobile: this.state.is_mobile,
        type_browser: this.state.type_browser,
        photo_url: this.state.photo_url,
    };
    //alert(vivi.Rowkey);

    axios
    .post("https://sokika-api.azurewebsites.net/api/charities/", vivi)
    .then((res) => {
      data = res.data;
      this.setState({
        details: data,
      });
      this.setState({detail: this.state.details[0].detail, 
        ext_link: this.state.details[0].ext_link,
      photo_url: this.state.details[0].photo_url})
      //alert('c=' + this.state.details.length + ' detail=' + this.state.quote + ', ext=' + this.state.ext_link)
    })
    .catch((err) => {alert(err)});
  }

  //renderswitch

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const article = {
      RowKey: sessionStorage.getItem('RowKey'),
      PartitionKey: sessionStorage.getItem('PartitionKey'),
      ext_link: this.state.ext_link,
      detail: this.state.detail,
      photo_url:
        "https://reactcampusstorage.blob.core.windows.net/reactimages/" +
        this.state.photo_url,
      email: sessionStorage.getItem('email'),
      url_referer: this.state.url_referer,
      is_mobile: this.state.is_mobile,
      type_browser: this.state.type_browser,
    };

    this.setState({
      file2: null,
    });

    axios
      .put("https://sokika-api.azurewebsites.net/api/charities", article)
      .then((response) =>
        this.setState({
          detail: "",
          ext_link: "Patch successful!",
          //photo_url: "",
        })
      )
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  };

  render() {
    return (
      <div>
        <Laroutebas />
        <p style={{ marginTop: "60px" }}></p>

        <div
          style={{
            backgroundColor: "",
            padding: "10px",
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "10px",
          }}
        >
          <div style={{ width: "auto" }}>
            <form onSubmit={this.handleSubmit}>
              <div style={{ maxWidth: "100%" }}>
                <div
                  class="form-group"
                  style={{
                    padding: "5px",
                  }}
                >
                  <br />
                  <input
                    defaultValue={this.state.photo_url}
                    name="photo_url"
                    type="file"
                    onChange={this.onFileChange}
                    className="form-control-file"
                    id="exampleFormControlFile1"
                    style={{ display: "none" }}
                  />
                  <label for="exampleFormControlFile1">
                    <span
                      style={{
                        cursor: "pointer",
                        fontFamily: "Poller One",
                      }}
                    >
                      <span>🚀</span> Upload Image <span>🚀</span>
                    </span>
                  </label>
                  <Online />
                </div>
                {this.fileData()}
                <div className="form-group">
                <a
                          href={this.state.ext_link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={this.state.photo_url}
                            style={{ borderRadius: "5%" }}
                            alt="www.sokika.com"
                            width="100%"
                          />{" "}
                        </a>
                </div>
                <div className="form-group">
                  <textarea
                    class="form-control"
                    rows="10"
                    id="detail"
                    name="detail"
                    value={this.state.detail}
                    maxLength={850}
                    onChange={this.handleInput}
                    placeholder="Tell me in 850 characters ..."
                    required
                    style={{
                      backgroundColor: "lightyellow",
                      border: "1px solid green",
                    }}
                  ></textarea>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Paste external link (OPTIONAL) ..."
                    maxLength={500}
                    value={this.state.ext_link}
                    id="ext_link"
                    name="ext_link"
                    onChange={this.handleInput}
                    style={{
                      backgroundColor: "lightyellow",
                      border: "1px solid green",
                    }}
                  />
                </div>
              </div>

              <div class="d-grid gap-2">
                <button
                  onClick={this.onFileUpload}
                  type="submit"
                  class="btn btn-primary btn-block"
                  style={{
                    fontFamily: "Fahkwang",
                    fontSize: "2em",
                    padding: "10px",
                  }}
                >
                  NGAMBAYE
                </button>
              </div>
            </form>
          </div>

          <hr
            style={{
              color: "#000000",
              backgroundColor: "#000000",
              height: 0.5,
              borderColor: "#000000",
            }}
          />
          <br />
        </div>
      </div>
    );
  }
  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        this.setState({
          ip: sessionStorage.setItem("ip", data.ip),
          network: sessionStorage.setItem("network", data.network),
          version: sessionStorage.setItem("version", data.version),
          city: sessionStorage.setItem("city", data.city),
          region: sessionStorage.setItem("region", data.region),
          region_code: sessionStorage.setItem("region_code", data.region_code),
          country: sessionStorage.setItem("country", data.country),
          country_name: sessionStorage.setItem(
            "country_name",
            data.country_name
          ),
          country_code: sessionStorage.setItem(
            "country_code",
            data.country_code
          ),
          country_code_iso3: sessionStorage.setItem(
            "country_code_iso3",
            data.country_code_iso3
          ),
          country_capital: sessionStorage.setItem(
            "country_capital",
            data.country_capital
          ),
          country_tld: sessionStorage.setItem("country_tld", data.country_tld),
          continent_code: sessionStorage.setItem(
            "continent_code",
            data.continent_code
          ),
          in_eu: sessionStorage.setItem("in_eu", data.in_eu),
          postal: sessionStorage.setItem("postal", data.postal),
          latitude: sessionStorage.setItem("latitude", data.latitude),
          longitude: sessionStorage.setItem("longitude", data.longitude),
          timezone: sessionStorage.setItem("timezone", data.timezone),
          utc_offset: sessionStorage.setItem("utc_offset", data.utc_offset),
          country_calling_code: sessionStorage.setItem(
            "country_calling_code",
            data.country_calling_code
          ),
          currency: sessionStorage.setItem("currency", data.currency),
          currency_name: sessionStorage.setItem(
            "currency_name",
            data.currency_name
          ),
          languages: sessionStorage.setItem("languages", data.languages),
          country_area: sessionStorage.setItem(
            "country_area",
            data.country_area
          ),
          country_population: sessionStorage.setItem(
            "country_population",
            data.country_population
          ),
          asn: sessionStorage.setItem("asn", data.asn),
          org: sessionStorage.setItem("org", data.org),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export default EditItem;
