import EditItem from "../components/EditItem";

const Details = () => {
  return (
    <div>
      <p style={{ marginTop: "60px", fontFamily: "Poller One" }}></p>
      {sessionStorage.getItem("email") === "ngambaye2020@gmail.com" ? (
        <EditItem />
      ) : (
        <p style={{ fontFamily: "Poller One" }}>Yeah!</p>
      )}
      <p style={{ marginBottom: "60px", fontFamily: "Poller One" }}></p>
    </div>
  );
};

export default Details;
