import Ngambaye from "../components/ngambaye";
import Sidebar from "../components/Sidebar";

const Devops = () => {
  return (
    <div style={{ padding: "10px", margin: "10px" }}>
      <p style={{ marginTop: "60px", fontFamily: "Poller One" }}></p>
      <div className="row">
        <div className="col-md-8">
          Professional Summary
          <br />
          <br />
          Experienced DevOps and Site Reliability Engineer (SRE) with 5 years of
          expertise in automating infrastructure, ensuring system reliability,
          and optimizing deployment processes. Proficient in cloud platforms,
          CI/CD pipelines, containerization, and monitoring tools. Strong
          background in collaborating with development and operations teams to
          build scalable, secure, and resilient systems.
          <br />
          <br />
          Core Competencies
          <br />
          <br />
          <ul>
            <li>Infrastructure as Code (Terraform, CloudFormation)</li>
            <li>Continuous Integration/Continuous Deployment (CI/CD)</li>
            <li>Cloud Platforms (AWS, Azure, Google Cloud)</li>
            <li>Configuration Management (Ansible, Chef, Puppet)</li>
            <li>Containerization & Orchestration (Docker, Kubernetes)</li>
            <li>Monitoring & Logging (Prometheus, Grafana, ELK Stack)</li>
            <li>Scripting & Automation (Python, Bash, PowerShell)</li>
            <li>High Availability & Disaster Recovery</li>
            <li>Performance Tuning & Optimization</li>
            <li>Security & Compliance (IAM, Encryption)</li>
          </ul>
          Solutions Architect
          <br />
          <br />
          As a Solutions Architect: 1 - I discuss business requirements with
          stakeholders, design and implement the solution. 2 - I have a solid
          academic education in networking, databases, programming and cloud
          computing 3 - My skills include full stack application development,
          DevOps and Site Reliability Engineering. 4 - I have 15 years of
          professional experience at Microsoft in multiple Azure engineering
          roles. 5 - I find joy in exploring new technologies, building proof of
          concept and mentoring.
        </div>
        <div className="col-md-4">
          <Sidebar />
        </div>
      </div>
      <p style={{ marginBottom: "60px", fontFamily: "Poller One" }}></p>
    </div>
  );
};

export default Devops;
