import React, { Component } from "react";
import Online from "./Online";
import { Link } from "react-router-dom";

class Laroutebas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //get ip
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
  }

  render() {
    return (
      <nav class="navbar navbar-light navbar-inverse navbar-fixed-bottom" style={{}}>
        <div class="container-fluid">
            <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#myNavbar2">
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>                        
            </button>
            <span class="navbar-brand" style={{fontFamily:"Poller One", fontSize:"1em"}}><Online /></span>
            {sessionStorage.getItem("email") ===
                        "ngambaye2020@gmail.com" ? (
                          <span class="navbar-brand" style={{fontFamily:"Poller One", fontSize:"1em"}}><Link to="/business">BUSINESS</Link></span>
                        ) : (
                          <span></span>
                        )}
            </div>
            <div class="collapse navbar-collapse" id="myNavbar" style={{fontFamily:"Poller One"}}>

            </div>
        </div>
      </nav>
    );
  }
}

export default Laroutebas;
