import App from "../App";

import React, { Component } from "react";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //get ip
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
  }

  render() {
    return <App />;
  }
}

export default Home;
