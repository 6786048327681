import Ngambaye from "../components/ngambaye";
import Sidebar from "../components/Sidebar";

const Aboutus = () => {
  return (
    <div style={{padding: "10px", margin: "10px"}}>
      <p style={{ marginTop: "60px", fontFamily: "Poller One" }}></p>
      <div className="row">
      <div className="col-md-8">
          Professional Summary
          <br /><br />
          Proactive and detail-oriented Cloud Database Administrator with over 5 years of experience in managing and optimizing databases across multiple cloud platforms including AWS, Azure, and Google Cloud. Expertise in database architecture, performance tuning, backup and recovery, and ensuring high availability in cloud environments. Demonstrated ability to design and implement scalable, secure, and reliable database solutions to meet business needs.
          <br />
          <br />
          Core Competencies
          <br /><br />
          <ul>
          <li>Cloud Databases (Amazon RDS, Azure SQL, Google Cloud SQL)</li>
          <li>Database Design & Architecture</li>
          <li>Performance Tuning & Optimization</li>
          <li>Backup & Recovery Strategies</li>
          <li>High Availability & Disaster Recovery</li>
          <li>Database Security & Compliance</li>
          <li>Automation & Scripting (Python, PowerShell, Bash)</li>
          <li>Monitoring & Maintenance (CloudWatch, Azure Monitor, Stackdriver)</li>
          <li>SQL & NoSQL Databases (MySQL, PostgreSQL, MongoDB, Cassandra)</li>
          <li>Data Migration & Replication</li>
          </ul>
          Solutions Architect
          <br /><br />
          As a Solutions Architect: 1 - I discuss business requirements with
          stakeholders, design and implement the solution. 2 - I have a solid
          academic education in networking, databases, programming and cloud
          computing 3 - My skills include full stack application development,
          DevOps and Site Reliability Engineering. 4 - I have 15 years of
          professional experience at Microsoft in multiple Azure engineering
          roles. 5 - I find joy in exploring new technologies, building proof of
          concept and mentoring.
        </div>
  <div className="col-md-4">
    <Sidebar />
  </div>
</div>
      <p style={{ marginBottom: "60px", fontFamily: "Poller One" }}></p>
    </div>
  );
};

export default Aboutus;
