import axios from "axios";
import React, { Component } from "react";
//import Button from "react-bootstrap/Button";

class Online extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      name: props,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 10000);
  }

  componentWillUnmount() {
    //
  }

  tick() {
    let data;

    const person = {
      name: "nothing",
    };

    axios
      .post(`https://sokika-api.azurewebsites.net/api/twitter`, person)
      .then((res) => {
        data = res.data;
        this.setState({
          details: data,
        });
      });
  }

  render() {
    return (
      <span style={{fontFamily:"Roboto", marginLeft:"7px"}}>
        {this.state.details.map((detail, id) => (
          <span key={id}>
            {detail.name}
            </span>
        ))}
      </span>
    );
  }
}

export default Online;
