import Listings from "../components/listings";

const Business = () => {
  return (
    <div>
      <p style={{ marginTop: "60px", fontFamily: "Poller One" }}></p>
      {sessionStorage.getItem("email") === "ngambaye2020@gmail.com" ? (
        <Listings />
      ) : (
        <p style={{ fontFamily: "Poller One" }}>Yeah!</p>
      )}
      <p style={{ marginBottom: "60px", fontFamily: "Poller One" }}></p>
    </div>
  );
};

export default Business;
