import axios from "axios";
import React, { Component } from "react";
//import * as ReactBootstrap from "react-bootstrap";

class Likes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countoflikes: 0,
      name: "detail",
      exposed: props,
      //email: "",
    };
    this.handleSubmit = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 5000);
  }

  tick() {
    const noti = {
      partitionkey: this.props.PartitionKey,
      rowkey: this.props.RowKey,
      name: this.state.name,
      //email: "",
    };

    axios
      .post("https://sokika-api.azurewebsites.net/api/messagerie", noti)
      .then((res) => {
        const person = res.data;
        this.setState({ countoflikes: person.likes_count });
        //console.log(res.data);
      });
    //console.log("countoflikes=" + this.state.countoflikes);
  }

  handleClick = (event) => {
    //event.preventDefault();
    this.setState({ countoflikes: this.state.countoflikes + 1 });

    //console.log(this.props);
    const person = {
      partitionkey: this.props.PartitionKey,
      rowkey: this.props.RowKey,
      name: this.state.name,
      //email: sessionStorage.getItem("email"),
      ip: sessionStorage.getItem("ip"),
      city: sessionStorage.getItem("city"),
      country: sessionStorage.getItem("country"),
    };

    axios
      .put("https://sokika-api.azurewebsites.net/api/exposed", person)
      .then((response) => this.setState({ retour: "Awesome!" }))
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
    //alert("lk-put=" + this.state.countoflikes);
  };

  render() {
    return (
      <span
        style={{
          border: "1px solid green",
          marginLeft: "10px",
          cursor: "pointer",
          padding: "5px",
        }}
        onClick={() => this.handleClick(this)}
        class="badge bg-warning text-dark"
      >
        <span>🚀</span>{" "}
        {this.state.countoflikes}{"  "} Likes
      </span>
    );
  }
}

export default Likes;
