import Ngambaye from "../components/ngambaye";
import Sidebar from "../components/Sidebar";

const Customers = () => {
  return (
    <div style={{ padding: "10px", margin: "10px" }}>
      <p style={{ marginTop: "60px", fontFamily: "Poller One" }}></p>
      <div className="row">
        <div className="col-md-8">
          Professional Summary
          <br /><br />
          Innovative Machine Learning Engineer with over 5 years of experience
          in designing, developing, and deploying machine learning models in
          cloud environments. Proficient in leveraging cloud platforms such as
          AWS, Azure, and Google Cloud for scalable ML solutions. Strong
          background in data science, model optimization, and MLOps, with a
          proven ability to translate business needs into robust machine
          learning solutions.
          <br />
          <br />
          Core Competencies
          <br /><br />
          <ul>
            <li>Machine Learning & AI</li>
            <li>Cloud Computing (AWS, Azure, GCP)</li>
            <li>Data Engineering & ETL</li>
            <li>MLOps & CI/CD Pipelines</li>
            <li>Model Deployment & Monitoring</li>
            <li>Big Data Technologies</li>
            <li>Programming (Python, R, Java, Scala)</li>
            <li>Deep Learning (TensorFlow, PyTorch)</li>
            <li>Data Visualization & Analytics</li>
            <li>Security & Compliance</li>
          </ul>
          Solutions Architect
          <br /><br />
          As a Solutions Architect: 1 - I discuss business requirements with
          stakeholders, design and implement the solution. 2 - I have a solid
          academic education in networking, databases, programming and cloud
          computing 3 - My skills include full stack application development,
          DevOps and Site Reliability Engineering. 4 - I have 15 years of
          professional experience at Microsoft in multiple Azure engineering
          roles. 5 - I find joy in exploring new technologies, building proof of
          concept and mentoring.
        </div>
        <div className="col-md-4">
          <Sidebar />
        </div>
      </div>

      <p style={{ marginBottom: "60px", fontFamily: "Poller One" }}></p>
    </div>
  );
};

export default Customers;
