import Ngambaye from "../components/ngambaye";

const NoPage = () => {
  return (
    <div>
      <p style={{ marginTop: "60px", fontFamily: "Poller One" }}>No Page!</p>
      <Ngambaye name="nopage" />
      <p style={{ marginBottom: "60px", fontFamily: "Poller One" }}></p>
    </div>
  );
};

export default NoPage;
