import axios from "axios";
import React, { Component } from "react";

class Location extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      name: props,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 10000);
  }

  componentWillUnmount() {
    //
  }

  tick() {
    let data;

    const person = {
      name: this.props.name,
    };

    axios
      .post(`https://sokika-api.azurewebsites.net/api/statistics`, person)
      .then((res) => {
        data = res.data;
        this.setState({
          details: data,
        });
      });
  }

  render() {
    return (
      <div style={{padding:"10px", borderLeft:"1px solid green"}}>
        <div style={{ fontFamily: "Poller One" }}>Top {this.props.name}</div>
        <br />
        <div>
          {this.state.details.map((detail, id) => (
            <div key={id}>
                <table style={{width:"100%"}}>
                  <tr>
                    <td>{detail.name}</td>
                    <td style={{ color: "red", textAlign:"right" }}>{detail.views_count}</td>
                  </tr>
                </table>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Location;
