import { Outlet, Link } from "react-router-dom";
import Laroutebas from "../components/laroutebas";

const Layout = () => {
  return (
    <div>
      <Laroutebas />
      <nav class="navbar navbar-inverse navbar-fixed-top" style={{}}>
        <div class="container-fluid">
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target="#myNavbar"
            >
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <a
              class="navbar-brand"
              style={{ fontFamily: "Poller One", fontSize: "2em" }}
              href="/"
            >
              SOKIKA LLC
            </a>
          </div>
          <div
            class="collapse navbar-collapse"
            id="myNavbar"
            style={{ fontFamily: "Poller One" }}
          >
            <ul class="nav navbar-nav">
              <li>
                <Link to="aboutus">Cloud DBA</Link>
              </li>
              <li>
                <Link to="/solutions">Data Engineer</Link>
              </li>
              <li>
                <Link to="/customers">ML Engineer</Link>
              </li>
              <li>
                <Link to="/careers">AI Engineer</Link>
              </li>
              <li>
                <Link to="/charities">Data Scientist</Link>
              </li>
              <li>
                <Link to="/devops">DevOps/SRE</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <Outlet />
    </div>
  );
};

export default Layout;
