import React from "react";
import axios from "axios";
import { browserName, isMobile } from "react-device-detect";
import Likes2 from "../components/Likes2";
import Delete2 from "../components/Delete2";
//import Poke from "../components/Poke";
import News from "../components/News";
import Remove2 from "../components/Remove2";

//client 72944838415-9u3d201g3ks5peekus6ar6o14b5b3dab.apps.googleusercontent.com
//sec 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

class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      user: "usr",
      quote: "",
      ext_link: "",
      url_referer: document.referrer,
      is_mobile: isMobile,
      type_browser: browserName,
      guid: "",
      photo_url: "",
      PartitionKey: this.props.PartitionKey,
      RowKey: this.props.RowKey,
      textflag: false,
      counto: 0,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  ToggleButton() {
    this.setState({ textflag: !this.state.textflag });
  }

  handleChange = (event) => {
    event.preventDefault();
    this.setState({ [event.target.name]: event.target.value });
  };

  componentWillMount() {
    this.getGeoInfo();
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 10000);
  }

  tick() {
    let data;

    const comm = {
      RowKey: this.state.RowKey,
      PartitionKey: this.state.PartitionKey,
    };

    axios
      .post("https://sokika-api.azurewebsites.net/api/comment/", comm)
      .then((res) => {
        data = res.data;
        this.setState({
          details: data,
        });
      })
      .catch((err) => {});
  }

  renderSwitch = (param) => {
    switch (param + 1) {
      case 1:
        return "info";
      case 2:
        return "info";
      case 3:
        return "info";
      case 4:
        return "info";
      case 5:
        return "info";
      case 6:
        return "info";
      default:
        return "yellow";
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const article = {
      name: this.state.user,
      detail: this.state.quote,
      guid: sessionStorage.getItem("guid"),
      picture_url: sessionStorage.getItem("picture_url"),
      email: sessionStorage.getItem("email"),
      category: "main",
      ip: sessionStorage.getItem("ip"),
      network: sessionStorage.getItem("network"),
      version: sessionStorage.getItem("version"),
      city: sessionStorage.getItem("city"),
      region: sessionStorage.getItem("region"),
      region_code: sessionStorage.getItem("region_code"),
      country: sessionStorage.getItem("country"),
      country_name: sessionStorage.getItem("country_name"),
      country_code: sessionStorage.getItem("country_code"),
      country_code_iso3: sessionStorage.getItem("country_code_iso3"),
      country_capital: sessionStorage.getItem("country_capital"),
      country_tld: sessionStorage.getItem("country_tld"),
      continent_code: sessionStorage.getItem("continent_code"),
      in_eu: sessionStorage.getItem("in_eu"),
      postal: sessionStorage.getItem("postal"),
      latitude: sessionStorage.getItem("latitude"),
      longitude: sessionStorage.getItem("longitude"),
      timezone: sessionStorage.getItem("timezone"),
      utc_offset: sessionStorage.getItem("utc_offset"),
      country_calling_code: sessionStorage.getItem("country_calling_code"),
      currency: sessionStorage.getItem("currency"),
      currency_name: sessionStorage.getItem("currency_name"),
      languages: sessionStorage.getItem("languages"),
      country_area: sessionStorage.getItem("country_area"),
      country_population: sessionStorage.getItem("country_population"),
      asn: sessionStorage.getItem("asn"),
      org: sessionStorage.getItem("org"),
      //pub_date: currentDate,
      views_count: 0,
      likes_count: 0,
      dislikes_count: 0,
      flags_count: 0,
      photo_url: "nophoto",
      post_status: "active",
      phone: "+0000000000",
      whatsapp: "+0000000000",
      education: "twitter",
      profession: window.location.href,
      req_education: "req",
      req_experience: "req",
      req_location: this.state.PartitionKey,
      req_industry: this.state.RowKey,
      req_sector: "req",
      req_salary: "req",
      req_term: "req",
      req_level: "req",
      req_benefits: "req",
      req_clearance: "req",
      req_certification: "req",
      req_linkedin: "req",
      ext_link: this.state.ext_link,
      url_referer: this.state.url_referer,
      is_mobile: this.state.is_mobile,
      type_browser: this.state.type_browser,
    };

    axios
      .post("https://sokika-api.azurewebsites.net/api/commentaire", article)
      .then((response) =>
        this.setState({
          quote: "Post successful!",
          //ext_link: "Post successful!",
          //photo_url: "",
        })
      )
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
  };

  render() {
    return (
      <div>
        <div
          style={{
            backgroundColor: "",
            padding: "10px",
            marginLeft: "0px",
            marginRight: "0px",
            marginBottom: "10px",
          }}
        >
          <div style={{ width: "auto" }}>
            <span
              style={{ cursor: "pointer", color: "blue" }}
              onClick={() => this.ToggleButton()}
            >
              Toggle Comments ({this.state.details.length})
            </span>
            <br />
            <br />
            {!this.state.textflag}
            {this.state.textflag === true ? (
              <div>
                <div>
                  {this.state.details.map((detail, id) => (
                    <div key={id}>
                      <div
                        className="card shadow-lg"
                        style={{ fontFamily: "Roboto" }}
                      >
                        <div
                          className={
                            "bg-" + this.renderSwitch(id % 6) + " card-header"
                          }
                          style={{ backgroundColor: "White" }}
                        >
                          <table>
                            <tr>
                              <td>
                                <img
                                  src={detail.picture_url}
                                  alt="ngambaye"
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    borderRadius: "50%",
                                    padding: "0px",
                                  }}
                                />
                              </td>
                              <td>
                                <span
                                  style={{
                                    padding: "10px",
                                    fontSize: "0.9em",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <i>
                                    by {detail.guid}, {detail.req_term}
                                  </i>
                                </span>
                              </td>
                            </tr>
                          </table>

                          <p
                            style={{
                              padding: "10px",
                            }}
                          >
                            {detail.detail}
                          </p>
                          <p
                            style={{
                              borderLeft: "1px solid green",
                              borderRight: "1px solid green",
                            }}
                          >
                            <Likes2 {...detail} /> <Delete2 {...detail} />{" "}
                            {sessionStorage.getItem("email") ===
                            "ngambaye2020@gmail.com" ? (
                              <News />
                            ) : (
                              <Remove2 />
                            )}
                            <br />
                          </p>
                        </div>
                        <br />
                      </div>
                      <span className="border border-primary "></span>
                    </div>
                  ))}
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div style={{ maxWidth: "100%" }}>
                    <div className="form-group">
                      <textarea
                        class="form-control"
                        rows="5"
                        id="quote"
                        name="quote"
                        value={this.state.quote}
                        maxLength={600}
                        onChange={this.handleInput}
                        placeholder="Comment in 600 characters ..."
                        required
                        style={{
                          backgroundColor: "lightyellow",
                          border: "1px solid green",
                        }}
                      ></textarea>
                    </div>
                  </div>

                  <div class="d-grid gap-2">
                    <button
                      onClick={this.onFileUpload}
                      type="submit"
                      class="btn btn-default btn-block"
                      style={{
                        fontFamily: "Poller One",
                        fontSize: "2em",
                        padding: "5px",
                      }}
                    >
                      NGAMBAYE
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <span></span>
            )}
          </div>
        </div>
      </div>
    );
  }
  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        this.setState({
          ip: sessionStorage.setItem("ip", data.ip),
          network: sessionStorage.setItem("network", data.network),
          version: sessionStorage.setItem("version", data.version),
          city: sessionStorage.setItem("city", data.city),
          region: sessionStorage.setItem("region", data.region),
          region_code: sessionStorage.setItem("region_code", data.region_code),
          country: sessionStorage.setItem("country", data.country),
          country_name: sessionStorage.setItem(
            "country_name",
            data.country_name
          ),
          country_code: sessionStorage.setItem(
            "country_code",
            data.country_code
          ),
          country_code_iso3: sessionStorage.setItem(
            "country_code_iso3",
            data.country_code_iso3
          ),
          country_capital: sessionStorage.setItem(
            "country_capital",
            data.country_capital
          ),
          country_tld: sessionStorage.setItem("country_tld", data.country_tld),
          continent_code: sessionStorage.setItem(
            "continent_code",
            data.continent_code
          ),
          in_eu: sessionStorage.setItem("in_eu", data.in_eu),
          postal: sessionStorage.setItem("postal", data.postal),
          latitude: sessionStorage.setItem("latitude", data.latitude),
          longitude: sessionStorage.setItem("longitude", data.longitude),
          timezone: sessionStorage.setItem("timezone", data.timezone),
          utc_offset: sessionStorage.setItem("utc_offset", data.utc_offset),
          country_calling_code: sessionStorage.setItem(
            "country_calling_code",
            data.country_calling_code
          ),
          currency: sessionStorage.setItem("currency", data.currency),
          currency_name: sessionStorage.setItem(
            "currency_name",
            data.currency_name
          ),
          languages: sessionStorage.setItem("languages", data.languages),
          country_area: sessionStorage.setItem(
            "country_area",
            data.country_area
          ),
          country_population: sessionStorage.setItem(
            "country_population",
            data.country_population
          ),
          asn: sessionStorage.setItem("asn", data.asn),
          org: sessionStorage.setItem("org", data.org),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export default Comment;
