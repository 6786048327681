import React, { useState, useEffect } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

function Intro() {
  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          if (typeof res !== "undefined" && res) {
            if (res.status !== "unknown") {
              if (typeof Storage !== "undefined") {
                //console.log(res.data.email);
                sessionStorage.setItem("email", res.data.email);
                sessionStorage.setItem("guid", res.data.name);
                sessionStorage.setItem("picture_url", res.data.picture);
              }
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
    sessionStorage.removeItem("email");
    sessionStorage.removeItem("guid");
    sessionStorage.removeItem("picture_url");
  };

  return (
    <div
      style={{
        fontFamily: "Fahkwang",
      }}
    >
    <div
            style={{
              textAlign: "center",
              width: "100%",
              borderRadius: "5%",
            }}
          >
            {profile ? (
              <div style={{}}>
                <button onClick={logOut} class="btn btn-success" style={{width:"100%", padding:"10px"}}>
                  <span>🚀</span> Log out <span>🚀 {profile.name}</span>
                </button>
              </div>
            ) : (
              <div style={{}}>
                <button onClick={login} class="btn btn-warning" style={{width:"100%", padding:"10px"}}>
                  <span>🚀</span> Sign in with Google <span>🚀</span>
                </button>
              </div>
            )}
          </div>
    </div>
  );
}
export default Intro;
