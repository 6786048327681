import Ngambaye from "../components/ngambaye";
import Sidebar from "../components/Sidebar";

const Solutions = () => {
  return (
    <div style={{ padding: "10px", margin: "10px" }}>
      <p style={{ marginTop: "60px", fontFamily: "Poller One" }}></p>
      <div className="row">
      <div className="col-md-8">
          Professional Summary
          <br /><br />
          Detail-oriented and innovative Data Engineer with 5 years of experience in designing, developing, and maintaining robust data pipelines and architectures. Skilled in data warehousing, ETL processes, big data technologies, and cloud platforms. Adept at transforming raw data into actionable insights, optimizing data processing, and enabling advanced analytics to support business decision-making.
          <br />
          <br />
          Core Competencies
          <br /><br />
          <ul>
          <li>Data Pipeline Development</li>
          <li>ETL & Data Integration</li>
          <li>Data Warehousing (Redshift, BigQuery, Snowflake)</li>
          <li>Big Data Technologies (Hadoop, Spark, Kafka)</li>
          <li>Cloud Platforms (AWS, Azure, GCP)</li>
          <li>SQL & NoSQL Databases (MySQL, PostgreSQL, MongoDB)</li>
          <li>Programming Languages (Python, Java, Scala)</li>
          <li>Data Modeling & Schema Design</li>
          <li>Data Governance & Quality</li>
          <li>Data Security & Compliance</li>
          </ul>
          Solutions Architect
          <br /><br />
          As a Solutions Architect: 1 - I discuss business requirements with
          stakeholders, design and implement the solution. 2 - I have a solid
          academic education in networking, databases, programming and cloud
          computing 3 - My skills include full stack application development,
          DevOps and Site Reliability Engineering. 4 - I have 15 years of
          professional experience at Microsoft in multiple Azure engineering
          roles. 5 - I find joy in exploring new technologies, building proof of
          concept and mentoring.
        </div>
        <div className="col-md-4">
          <Sidebar />
        </div>
      </div>

      <p style={{ marginBottom: "60px", fontFamily: "Poller One" }}></p>
    </div>
  );
};

export default Solutions;
