import axios from "axios";
import React, { Component } from "react";

class Secret extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: [],
      name: props,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 10000);
  }

  componentWillUnmount() {
    //
  }

  tick() {
    let data;

    axios
      .get(`https://sokika-api.azurewebsites.net/api/bingnews`)
      .then((res) => {
        data = res.data;
        this.setState({
          details: data,
        });
      });
  }

  render() {
    return (
        <span style={{fontFamily:"Poller One"}}>
            {this.state.details.map((detail, id) => (
            <span key={id}>
                {detail.name}
            </span>
            ))}
      </span>
    );
  }
}

export default Secret;
