import axios from "axios";
import React, { Component } from "react";
//import * as ReactBootstrap from "react-bootstrap";

class Likes2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countoflikes: 0,
      name: "detail",
      exposed: props,
      //email: "",
    };
    this.handleSubmit = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 5000);
  }

  tick() {
    const noti = {
      partitionkey: this.props.PartitionKey,
      rowkey: this.props.RowKey,
      name: this.state.name,
      //email: "",
    };

    axios
      .post("https://sokika-api.azurewebsites.net/api/messagerie", noti)
      .then((res) => {
        const person = res.data;
        this.setState({ countoflikes: person.likes_count });
        //console.log(res.data);
      });
    //console.log("countoflikes=" + this.state.countoflikes);
  }

  handleClick = (event) => {
    //event.preventDefault();
    this.setState({ countoflikes: this.state.countoflikes + 1 });

    //console.log(this.props);
    const person = {
      partitionkey: this.props.PartitionKey,
      rowkey: this.props.RowKey,
      name: this.state.name,
      ip: sessionStorage.getItem("ip"),
      //email: sessionStorage.getItem("email"),
      city: sessionStorage.getItem("city"),
      country: sessionStorage.getItem("country"),
    };

    axios
      .put("https://sokika-api.azurewebsites.net/api/exposed", person)
      .then((response) => this.setState({ retour: "Awesome!" }))
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
    //alert("lk-put=" + this.state.countoflikes);
  };

  render() {
    return (
      <span
        style={{
          marginLeft: "10px",
          cursor: "pointer",
          padding: "5px",
        }}
        onClick={() => this.handleClick(this)}
      >
        <span title="Like" style={{ fontSize: "1em", fontColor: "White" }}>
          <i class="fa-solid fa-thumbs-up"></i>
        </span>{" "}
        {"   "}
        <span style={{ fontSize: "1em" }}>{this.state.countoflikes}</span>
      </span>
    );
  }
}

export default Likes2;
