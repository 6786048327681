import Ngambaye from "../components/ngambaye";
import Sidebar from "../components/Sidebar";

const Charities = () => {
  return (
    <div style={{padding: "10px", margin: "10px"}}>
      <p style={{ marginTop: "60px", fontFamily: "Poller One" }}></p>
      <div className="row">
      <div className="col-md-8">
          Professional Summary
          <br /><br />
          Data Scientist with 5 years of experience in leveraging statistical analysis, machine learning, and data visualization to solve complex business problems. Proficient in developing predictive models, performing data mining, and generating actionable insights that drive strategic decisions. Strong background in working with large datasets, implementing advanced algorithms, and collaborating with cross-functional teams to optimize business processes.
          <br />
          <br />
          Core Competencies
          <br /><br />
          <ul>
          <li>Data Analysis & Statistical Modeling</li>
          <li>Machine Learning & Predictive Modeling</li>
          <li>Big Data Technologies (Hadoop, Spark)</li>
          <li>Data Visualization (Tableau, Power BI, Matplotlib, Seaborn)</li>
          <li>Programming (Python, R, SQL)</li>
          <li>Data Wrangling & Preprocessing</li>
          <li>Natural Language Processing (NLP)</li>
          <li>A/B Testing & Experimentation</li>
          <li>Cloud Platforms (AWS, Azure, GCP)</li>
          <li>Data Mining & Feature Engineering</li>
          </ul>
          Solutions Architect
          <br /><br />
          As a Solutions Architect: 1 - I discuss business requirements with
          stakeholders, design and implement the solution. 2 - I have a solid
          academic education in networking, databases, programming and cloud
          computing 3 - My skills include full stack application development,
          DevOps and Site Reliability Engineering. 4 - I have 15 years of
          professional experience at Microsoft in multiple Azure engineering
          roles. 5 - I find joy in exploring new technologies, building proof of
          concept and mentoring.
        </div>
        <div className="col-md-4">
          <Sidebar />
        </div>
      </div>
      <p style={{ marginBottom: "60px", fontFamily: "Poller One" }}></p>
    </div>
  );
};

export default Charities;
