import axios from "axios";
import React, { Component } from "react";
//import * as ReactBootstrap from "react-bootstrap";

class Poke extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //countoflikes: "",
      //name: "detail",
      exposed: props,
      //email: "",
      retour: "",
    };
    this.handleSubmit = this.handleClick.bind(this);
  }

  componentDidMount() {
    //this.interval = setInterval(() => this.tick(), 5000);
  }

  handleClick = (event) => {
    //event.preventDefault();

    //console.log(this.props);
    const noti = {
      partitionkey: this.props.PartitionKey,
      rowkey: this.props.RowKey,
      email: sessionStorage.getItem("email"),
      guid: sessionStorage.getItem("guid"),
    };

    axios
      .post("https://sokika-api.azurewebsites.net/api/poke", noti)
      .then((response) => this.setState({ retour: "Awesome!" }))
      .catch((error) => {
        this.setState({ errorMessage: error.message });
        console.error("There was an error!", error);
      });
    alert("Poke received!");
  };

  render() {
    return (
      <span
        style={{
          border: "1px solid green",
          marginLeft: "10px",
          cursor: "pointer",
          padding:"5px"
        }}
        onClick={() => this.handleClick(this)}
        class="badge bg-warning text-dark"
      >
        <span>🚀</span> Poke
      </span>
    );
  }
}

export default Poke;
